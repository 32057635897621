import { Box, Flex, Heading, HStack, Icon, IconButton, useBoolean } from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { theme } from '../../@chakra-ui/gatsby-plugin/theme';
import Question from '../../images/question.svg';
import Arrow from '../../images/arrow.svg';

interface Props extends PropsWithChildren {
    title: string;
    defaultExpanded?: boolean;
}

const MAX_HEIGHT = 50;
const PADDING_Y = 24; // px
const INITIAL_HEIGHT = 10000;

export const QuestionCard = ({ children, title, defaultExpanded = false }: Props) => {
    const [expanded, { toggle }] = useBoolean(defaultExpanded);
    const [initialHeight, setInitialHeight] = useState(INITIAL_HEIGHT);
    const [maxHeight, setMaxHeight] = useState(INITIAL_HEIGHT);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref.current != null && initialHeight === INITIAL_HEIGHT) {
            setInitialHeight(ref.current.scrollHeight);
            setMaxHeight(ref.current.scrollHeight);
        }

        const handler = () => {
            if (ref.current != null) {
                setInitialHeight(ref.current.scrollHeight);
            }
        };

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    useEffect(() => {
        if (ref.current != null) {
            if (!expanded) {
                setMaxHeight(MAX_HEIGHT);
            } else {
                setMaxHeight(ref.current.scrollHeight);
            }
        }
    }, [expanded, initialHeight]);

    return (
        <Flex
            ref={ref}
            direction="column"
            alignSelf="stretch"
            mx={['2', '0']}
            px="6"
            py={PADDING_Y / 4} // to get chakra spacings
            gap="8"
            border="1px"
            borderRadius="xl"
            borderColor={theme.brandColors.border}
            backgroundColor={theme.brandColors.background}
            shadow="0px 6px 15px rgba(26, 27, 56, 0.25)"
            transition="max-height 300ms ease"
            maxH={maxHeight + 2 * PADDING_Y}
            id={`expandable-${title}`}
            role="region"
        >
            <Flex direction="row" gap="4" alignItems="center">
                <Icon w="10" h="auto" as={Question as any} flex="0 0 auto" />
                <HStack as="label" cursor="pointer" flex="1 1 auto">
                    <Heading as="h3" size="lg" flex="1 1 auto">
                        {title}
                    </Heading>
                    <IconButton
                        aria-controls={`expandable-${title}`}
                        aria-expanded={expanded}
                        flex="0 0 auto"
                        bg="none"
                        _active={{ bg: 'none' }}
                        _hover={{ bg: 'none' }}
                        aria-label="pokaż więcej"
                        w="12"
                        h="12"
                        borderRadius={'50%'}
                        icon={
                            <Icon
                                h="4"
                                w="auto"
                                as={Arrow}
                                transition="transform 300ms ease"
                                transform={`rotate(${expanded ? '180' : '0'}deg)`}
                            />
                        }
                        onClick={toggle}
                    />
                </HStack>
            </Flex>
            <Box mx={['2']} fontSize="lg" fontFamily="sans-serif">
                {children}
            </Box>
        </Flex>
    );
};
