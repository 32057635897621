import { Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = () => {
    return (
        <Layout>
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <QuestionCard defaultExpanded title="O firmie">
                        <List ml="6" styleType="disc">
                            <ListItem>30 lat obecności na rynku wyrobów stalowych</ListItem>
                            <ListItem>Wiele zrealizowanych inwestycji i prac ślusarsko-spawalniczych</ListItem>
                            <ListItem>20 lat doświadczenia w wykonywaniu balustrad</ListItem>
                            <ListItem>Realizacja zamówień prywatnych i przemysłowych</ListItem>
                            <ListItem>Wykonywanie innych wyrobów stalowych według dostarczonego projektu</ListItem>
                            <ListItem>Usługi na terenie Katowic oraz w promieniu 50 km od miasta</ListItem>
                        </List>
                    </QuestionCard>
                    <QuestionCard defaultExpanded title="Realizacja zamówienia">
                        <List ml="6" styleType="disc">
                            <ListItem> Wizyta właściciela firmy w miejscu wykonania zlecenia</ListItem>
                            <ListItem> Bezpłatne doradztwo w zakresie wyboru materiału, wzoru</ListItem>
                            <ListItem> Bezpłatna konsultacja i wycena</ListItem>
                            <ListItem> Ustalenie harmonogramu prac</ListItem>
                            <ListItem> Wykonanie pomiarów i projektu technicznego</ListItem>
                            <ListItem> Ewentualna korekta i akceptacja klienta</ListItem>
                            <ListItem> Podjęcie prac</ListItem>
                            <ListItem> Gwarancja zgodnie z przepisami Unii Europejskiej</ListItem>
                        </List>
                    </QuestionCard>
                    <QuestionCard defaultExpanded title="Stosowane materiały">
                        <Heading as="h4" size="md" mb="4">
                            {`Balustrady ze stali zwykłej (tzw. "czarnej")`}
                        </Heading>
                        <List ml="6" styleType="disc">
                            <ListItem>niższy koszt</ListItem>
                            <ListItem>szeroki wybór wzorów (w tym balustrad kutych)</ListItem>
                            <ListItem>wymaga zabezpieczenia przed korozją</ListItem>
                            <List ml="6" styleType="circle">
                                <ListItem>ocynk ogniowy: najlepszy dla balustrad zewnętrznych</ListItem>
                                <ListItem>ocynk galwaniczny: nieco słabszy rodzaj zabezpieczenia</ListItem>
                                <ListItem>
                                    malowanie farbą antykorozyjną lub proszkową (wyłącznie dla balustrad wewnętrznych)
                                </ListItem>
                            </List>
                        </List>
                        <Heading as="h4" size="md" my="4">
                            Balustrady ze stali nierdzewnej (kwasoodpornej)
                        </Heading>
                        <List ml="6" styleType="disc">
                            <ListItem>wyższy koszt</ListItem>
                            <ListItem>nie koroduje</ListItem>
                            <ListItem>najtrwalszy materiał na balustrady zewnętrzne</ListItem>
                            <ListItem>brak możliwości wykonania balustrad kutych</ListItem>
                        </List>

                        <Heading as="h4" size="md" my="4">
                            Dostępne rodzaje balustrad
                        </Heading>

                        <List ml="6" styleType="disc">
                            <ListItem>balustrady systemowe</ListItem>
                            <List ml="6" styleType="circle">
                                <ListItem>składa się je z gotowych półproduktów</ListItem>
                                <ListItem>często są montowane w domach prywatnych</ListItem>
                            </List>
                            <ListItem>balustrady spawane</ListItem>
                            <List ml="6" styleType="circle">
                                <ListItem>są wytrzymalsze, bardziej odporne na uszkodzenia mechaniczne</ListItem>
                                <ListItem>są polecane dla budynków użytku publicznego</ListItem>
                            </List>
                        </List>
                        <Text mt="4" fontWeight="bold">
                            Nie wykonujemy balustrad aluminiowych!
                        </Text>
                    </QuestionCard>

                    <QuestionCard defaultExpanded title="Wykończenie">
                        <Heading as="h4" size="md" mb="4">
                            Polerowanie
                        </Heading>
                        <List ml="6" styleType="disc">
                            <ListItem> zapewnia elegancką powierzchnię odbijającą światło</ListItem>
                            <ListItem>ułatwia utrzymanie w czystości</ListItem>
                            <ListItem> balustrady polerowane są bardziej podatne na zarysowania</ListItem>
                        </List>

                        <Heading as="h4" size="md" my="4">
                            Szlifowanie
                        </Heading>
                        <List ml="6" styleType="disc">
                            <ListItem> zapewnia efektowne, satynowe wykończenie</ListItem>
                            <ListItem>potencjalne zarysowania są mniej widoczne</ListItem>
                            <ListItem>w zastosowaniach zewnętrznych, wymagają regularnego czyszczenia</ListItem>
                        </List>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;
